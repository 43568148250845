import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cards",
      "style": {
        "position": "relative"
      }
    }}>{`Cards`}</h1>
    <p>{`Cards são componentes da interface usados para agrupar informações relacionadas dentro de um container/bloco. Os cards funcionam como um contentor para algumas informações curtas e relacionadas. Os cards não possuem uma configuração de estilos para o conteúdo que é apresentado neles.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Cards geram uma leitura mais lenta do que listas e tabelas. Em listas simples verticais, o posicionamento padronizado e tamanho fixo de linhas é mais previsível e facilita a leitura. Em casos onde é mais provável que o usuário busque por um item específico dentre vários agrupamentos de informações, os cards podem prejudicar leitura. Neste caso, considere utilizar o componente de `}<a parentName="li" {...{
          "href": "/components/table"
        }}>{`Tabela`}</a>{`.`}</li>
      <li parentName="ul">{`Cards são uma boa escolha para dashboards que apresentam diferentes tipos de conteúdo na mesma página. Nessas situações, o card ajuda a criar uma diferenciação mais óbvia entre os itens, e ainda pode acomodar uma variedade de elementos.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-cards",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de Cards`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Leitura</th>
      <td>
        Utilizados para apresentar informações ao usuário. Esses cards podem conter CTA's (call-to-action) como botões e
        links.
      </td>
    </tr>
    <tr>
      <th>Clicável</th>
      <td>
        Utilizados como itens de navegação, podem redirecionar o usuário para outra página. Neste tipo de card não é
        recomendado utilizar CTA's.
      </td>
    </tr>
    <tr>
      <th>Selecionável</th>
      <td>
        Funcionam como um Checkbox, onde o card inteiro é um alvo de seleção. Cards de seleção funcionam bem para casos
        onde é necessário utilizar textos maiores que a recomendação do componente de{' '}
        <a href='/components/checkbox'>Checkbox</a>.
      </td>
    </tr>
    <tr>
      <th>Expansível</th>
      <td>
        Cards expansíveis utilizam o comportamento do componente <a href='/components/accordion'>Accordion</a>. O card
        expansível pode conter CTA's internos, se o CTA possuir sua própria área de clique.
      </td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      